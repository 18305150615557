import { Col, Row } from 'antd';
import QuizTracker from './QuizTracker';

const DashboardHeader = () => (
  <Row>
    <Col span={24}>
      <QuizTracker />
    </Col>
  </Row>
);
export default DashboardHeader;