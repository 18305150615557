import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { GET_CONCEPT_SUMMARY } from "../../Redux/Actions/StudentRadarChartActions";
import { Table, Space, Select, Row, Col, Card, List } from 'antd';
import ConceptRadarChartTemplateApex from '../ConceptRadarCharts/ConceptRadarChartTemplateApex';
import { GET_FEEDBACKS_FOR_UNIT_STRAND } from '../../Redux/Actions/StudentFeedbackActions';
import { ConceptFeedbacksMapper } from '../../Services/DataMappers/ConceptFeedbacksMapper';
import './styles.css';

// const { Panel } = Collapse;

const columns = [
  {
    title: 'Concept',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Questions Attempted',
    dataIndex: 'questionsAttempted',
    key: 'questionsAttempted',
  },
  {
    title: 'Concept Score',
    dataIndex: 'conceptScore',
    key: 'conceptScore',
  },
  {
    title: 'Feedback',
    key: 'action',
    render: (_, rec) => (
      <Space size="middle">
        <a href={rec.action} rel="noopener noreferrer" target="_blank">Get Feedback</a>
      </Space>
    ),
  },
];

export default function Feedback() {
  const dispatch = useAppDispatch();
  const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);
  const conceptRadarData = useAppSelector((state) => state.studentRadarGraphReducer.conceptSummary);
  const unitFeedbacks = useAppSelector((state) => state.studentFeedbacksReducer.feedbacks);
  const [strands, setStrands] = useState([]);
  const [selectedStrand, setSelectedStrand] = useState();
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const [feedbacks, setFeedbacks] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch({
      type: GET_CONCEPT_SUMMARY,
      payload: currentStudentEmail,
    });
  }, [dispatch, currentStudentEmail]);


  useEffect(() => {
    if (currentStudentEmail && selectedUnit && selectedStrand) {
      dispatch({
        type: GET_FEEDBACKS_FOR_UNIT_STRAND,
        payload: {
          email: currentStudentEmail,
          unit: selectedUnit,
          strand: selectedStrand,
        },
      });
    }
  }, [dispatch, currentStudentEmail, selectedUnit, selectedStrand]);

  useEffect(() => {
    if (conceptRadarData != null) {
      const strands = Object.keys(conceptRadarData);
      const units = Object.keys(conceptRadarData[strands[0]]);
      setStrands(strands);
      setSelectedStrand(strands[0]);
      setUnits(units);
      setSelectedUnit(units[0]);
    }
  }, [conceptRadarData]);

  useEffect(() => {
    if (unitFeedbacks != null) {
      setFeedbacks(unitFeedbacks);
    }
  }, [unitFeedbacks]);

  const extractValuesFromCategory = (unit, category) => {
    const correctValues = [];
    const subCategories = Object.keys(conceptRadarData[unit][category]);
    subCategories.forEach((subCategory) => {
      const value = conceptRadarData[unit][category][subCategory].value;
      correctValues.push(isNaN(value) ? 0 : value);
    });
    return correctValues;
  };

  const handleConceptsAndUnitsChange = (value, type) => {
    if (type === 'strand') {
      setSelectedStrand(value);
      const units = Object.keys(conceptRadarData[value]);
      setUnits(units);
      setSelectedUnit(units ? units[0] : undefined);
    } else if (type === 'unit') {
      setSelectedUnit(value);
    }
  };

  return (
    <div className="feedback-container">
      <Row gutter={[16, 16]} justify="center" className="filter-row">
        <Col xs={24} sm={12} md={8} lg={6} className="filter-comp">
          <label>Strand</label>
          <Select
            id="swat-unit"
            size="large"
            value={selectedStrand}
            onChange={(v) => handleConceptsAndUnitsChange(v, 'strand')}
            style={{ width: '100%' }}
            options={strands.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className="filter-comp">
          <label>Unit</label>
          <Select
            id="swat-concept"
            size="large"
            value={selectedUnit}
            onChange={(v) => handleConceptsAndUnitsChange(v, 'unit')}
            style={{ width: '100%' }}
            options={units.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="content-row">
        <Col xs={24} lg={12}>
          <Card className="radarchartContainerCS" hoverable>
            <div className="chart-title">Concept Breakdown</div>
            {conceptRadarData && selectedStrand && selectedUnit && (
              <ConceptRadarChartTemplateApex
                labels={Object.keys(conceptRadarData[selectedStrand][selectedUnit])}
                data={extractValuesFromCategory(selectedStrand, selectedUnit)}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card className="feedbackTable" hoverable>
            {feedbacks && feedbacks.length > 0 ? (
              <>
                <div className="feedbackTableTitle">Feedbacks</div>
                {isMobile ? (
                  // Mobile View: Display feedbacks in a List
                  <List
                    itemLayout="vertical"
                    dataSource={ConceptFeedbacksMapper(feedbacks)}
                    renderItem={(item) => (
                      <List.Item key={item.key}>
                        <Card>
                          <p><strong>Concept:</strong> {item.name}</p>
                          <p><strong>Questions Attempted:</strong> {item.questionsAttempted}</p>
                          <p><strong>Concept Score:</strong> {item.conceptScore}</p>
                          <Space size="middle">
                            <a href={item.action} rel="noopener noreferrer" target="_blank">Get Feedback</a>
                          </Space>
                        </Card>
                      </List.Item>
                    )}
                  />
                ) : (
                  // Desktop View: Display feedbacks in a Table
                  <Table
                    locale={{ emptyText: "No feedbacks received for this unit yet" }}
                    columns={columns}
                    dataSource={ConceptFeedbacksMapper(feedbacks)}
                    pagination={{ pageSize: 5 }}
                  />
                )}
              </>
            ) : (
              <div className="no-feedback">No feedbacks received for this unit yet.</div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
