// OverView.js

import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { GET_CONCEPT_SUMMARY } from "../../Redux/Actions/StudentRadarChartActions";
import { Layout } from 'antd';
import Strand from './Strand';
import DashboardHeader from './DashboardHeader';
import './styles.css';

const { Content } = Layout;

export default function OverView() {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.studentRadarGraphReducer.isLoading);
    const currentStudentEmail = useAppSelector((state) => state.studentProfileReducer.currentStudent.email);
    const conceptRadarData = useAppSelector((state) => state.studentRadarGraphReducer.conceptSummary);
    const [radarData, setRadarData] = useState(null);
    const [radarTabs, setRadarTabs] = useState([]);

    useEffect(() => {
        dispatch({
            type: GET_CONCEPT_SUMMARY,
            payload: currentStudentEmail
        });
    }, [dispatch, currentStudentEmail]);

    useEffect(() => {
        if (conceptRadarData != null) {
            setRadarData(conceptRadarData);
            setRadarTabs(Object.keys(conceptRadarData));
        }
    }, [conceptRadarData]);

    return (
      <Layout>
          <DashboardHeader />
          <Content className="overview-content">
              <Strand
                isLoading={isLoading}
                concepts={radarTabs}
                conceptData={radarData}
                onChange={() => {}}
              />
          </Content>
      </Layout>
    );
}
