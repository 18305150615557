export const GuideConstants = {
  descriptors: {
        questionType: "This radar chart shows how well a student understands different kinds of questions, like theory, composite, and worded questions. Each type of question has its own line on the chart. The farther out the line goes from the center, the better the student is at that kind of question. If the line is closer to the center, it means the student might need some help in that area.",
        questionLevel: "This radar chart shows how well is able to tackle different levels of questions. The farther out the line goes from the center, the better the student is at that kind of question. If the line is closer to the center, it means the student might need some help in that area.",
    },
    faqs: [
        {
          "question": "How do I read the charts?",
          "answer": "Each chart uses visual elements to represent the student's performance. The distance from the center in the radar chart or the height of the bars in the other chart indicates the strength level – the farther out or higher, the stronger the understanding."
        },
        {
          "question": "How can I use these charts to help my child/student?",
          "answer": "By analyzing both charts together, you can identify specific areas where the student excels and areas where they might need extra support. This allows for targeted teaching or studying methods tailored to their needs."
        },
        {
          "question": "Can I track progress over time with these charts?",
          "answer": "Yes! Monitoring changes in the charts over time can show how the student's understanding improves or where they may need more focus. It's a great way to track progress and adjust learning strategies accordingly."
        },
        {
          "question": "What if there are discrepancies between the charts?",
          "answer": "It's normal for students to have strengths and weaknesses across different areas of learning. These charts help pinpoint those differences so you can address them effectively."
        },
        {
          "question": "Are there resources available to improve weaker areas?",
          "answer": "Absolutely! We help you identify weaker areas and provide you with targeted resources, such as practice problems or additional explanations. Do refer to our individual feedbacks for more details."
        }
      ]

}