import React from 'react';
import { Collapse, Typography, List, Space } from 'antd';
import { QuestionCircleOutlined, InfoCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { GuideConstants } from './GuideConstants';
import './HowToGuideSA.css';

const { Panel } = Collapse;
const { Title, Paragraph, Text } = Typography;
// const { useBreakpoint } = Grid;

const HowToGuideSA = () => {
  // const screens = useBreakpoint();

  return (
    <div className="how-to-guide-container">
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition="right"
        className="how-to-guide-collapse"
      >
        <Panel
          header={
            <Space>
              <InfoCircleOutlined />
              <Text strong>Question Type</Text>
            </Space>
          }
          key="1"
        >
          <Paragraph className="panel-paragraph">
            {GuideConstants.descriptors.questionType}
          </Paragraph>
        </Panel>
        <Panel
          header={
            <Space>
              <FileTextOutlined />
              <Text strong>Question Level</Text>
            </Space>
          }
          key="2"
        >
          <Paragraph className="panel-paragraph">
            {GuideConstants.descriptors.questionLevel}
          </Paragraph>
        </Panel>
        <Panel
          header={
            <Space>
              <QuestionCircleOutlined />
              <Text strong>FAQs</Text>
            </Space>
          }
          key="3"
        >
          <List
            itemLayout="vertical"
            dataSource={GuideConstants.faqs}
            renderItem={(faq, index) => (
              <List.Item key={index} className="faq-item">
                <Title level={5} className="faq-question">
                  {faq.question}
                </Title>
                <Paragraph className="faq-answer">{faq.answer}</Paragraph>
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default HowToGuideSA;
