import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Image, Tooltip } from "antd";
import { LoginOutlined, FormOutlined, LogoutOutlined } from '@ant-design/icons';

import logo from "./Pydium_Logo.png";
import "./AppHeaderStyles.css";
import UserService from "../../Services/UserService";
import { useKeycloak } from "@react-keycloak/web";
// eslint-disable-next-line no-unused-vars
import StudentProfileInfo from "../StudentProfileInfo/StudentProfileInfo";
import { useEffect } from 'react';
import { GET_STUDENT_PROFILE } from '../../Redux/Actions/StudentProfileActions';
import { useAppDispatch } from '../../Redux/Store/hooks';

export default function NewAppHeader() {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const { keycloak } = useKeycloak();

    const redirectToHome = () => {
        navigate("/");
    };

    const navigateToDraw = () => {
        navigate("/draw");
    };

    const loginUser = () => {
        UserService.doLogin();
    };

    const userLogout = () =>  {
        UserService.doLogout();
    }


    const dispatch = useAppDispatch();
    useEffect(() => {
        console.log( UserService.getUserEmail())
        dispatch({
            type: GET_STUDENT_PROFILE,
            payload: {
                email: UserService.getUserEmail(),
            }
        })
    }, [keycloak.authenticated])

    return (
      <div className="headerContainer">
          <Row align="middle" justify="space-between" className="headerRow">
              {/* Left: Logo */}
              <Col className="headerLeft">
                  <Image
                    src={logo}
                    preview={false}
                    className="logo"
                    onClick={redirectToHome}
                  />
              </Col>

              {/* Center: Title */}
              <Col flex="auto" className="headerCenter">
                  <p className="headerTitle">MAPT</p>
              </Col>

              {/* Right: Icons or Profile */}
              <Col className="headerRight">
                  {/* {keycloak.authenticated ? ( */}
                  {/*  <StudentProfileInfo /> */}
                  {/* ) : ( */}
                    <Space size={24}>
                        <Tooltip placement="bottom" title="Draw">
                            <FormOutlined onClick={navigateToDraw} className="headerIcon" />
                        </Tooltip>

                            {keycloak.authenticated ? (
                              <Tooltip placement="bottom" title="Logout">
                              <LogoutOutlined onClick={userLogout} className="headerIcon"/>
                              </Tooltip>
                            ) : (
                              <Tooltip placement="bottom" title="Login">
                              <LoginOutlined onClick={loginUser} className="headerIcon" />
                              </Tooltip>
                            )}

                    </Space>
              </Col>
          </Row>
      </div>
    );
}
