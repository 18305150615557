import { CheckSquareOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Card, Statistic, Row, Col, Typography } from 'antd';
import DateTime from '../Common/DateTime';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import { useEffect, useState } from 'react';
import UserService from '../../Services/UserService';
import { GET_STUDENT_PROGRESS } from '../../Redux/Actions/StudentProfileActions';
import './styles.css';

const { Title } = Typography;

const initialProgress = {
  quizzesCompleted: 0,
  unitsCovered: 0,
};

const QuizTracker = () => {
  const dispatch = useAppDispatch();
  const currentStudentProgress = useAppSelector(
    (state) => state.studentProfileReducer.currentProgress
  );
  const [currentProgress, setProgress] = useState(initialProgress);

  useEffect(() => {
    dispatch({
      type: GET_STUDENT_PROGRESS,
      payload: {
        email: UserService.getUserEmail(),
      },
    });
  }, [dispatch]);

  useEffect(() => {
    setProgress(currentStudentProgress);
  }, [currentStudentProgress]);

  return (
    <Card bordered={false} className="quiz-tracker-card">
      <DateTime />
      <div className="tracker-header">
        <Title level={4} className="tracker-title">
          Student Progress
        </Title>
      </div>
      <Row gutter={[16, 16]} className="tracker-stats-row">
        <Col xs={12} sm={12}>
          <Statistic
            title="Quizzes Completed"
            value={currentProgress.quizzesCompleted}
            valueStyle={{ color: '#3f8600' }}
            prefix={<FileDoneOutlined />}
            className="tracker-statistic"
          />
        </Col>
        <Col xs={12} sm={12}>
          <Statistic
            title="Units Covered"
            value={currentProgress.unitsCovered}
            valueStyle={{ color: '#cf1322' }}
            prefix={<CheckSquareOutlined />}
            className="tracker-statistic"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default QuizTracker;
