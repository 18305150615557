import React from 'react';
import Chart from 'react-apexcharts';

// Utility function to generate shades of red
const generateRedShades = (numberOfShades) => {
  const redColors = [
    '#ff0000', // DarkRed
    '#bf1515', // FireBrick
    '#FF6347', // Tomato
    '#FF7F50', // Coral
    '#FFA07A'  // LightSalmon
  ];
  return redColors.slice(0, numberOfShades);
};

const UnderPerformersChart = ({ data }) => {
  // Generate red colors based on number of underperformers
  const redColors = generateRedShades(data.length);

  const chartOptions = {
    chart: { type: 'bar' },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true, // Enables individual bar colors
        dataLabels: {
          position: 'top', // Position data labels on top of bars
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: data.map(item => item.name),
      title: {
        text: 'Scores',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#333'
        }
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#333']
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: ['#333']
        }
      }
    },
    colors: redColors, // Assign dynamically generated red colors
    tooltip: {
      theme: 'dark'
    }
  };

  const series = [{ name: "Score", data: data.map(item => item.score) }];

  return (
    <div>
      <p>{data.length} Under Performers</p>
      <Chart options={chartOptions} series={series} type="bar" width="100%" />
    </div>
  );
};

export default UnderPerformersChart;
