import { useEffect, useState } from 'react';
import { Table, Skeleton, Badge, Select, Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const { Option } = Select;
const { Title } = Typography;

export default function Strand(props) {
  const [selectedConcept, setSelectedConcept] = useState('');
  const navigate = useNavigate();

  const onUnitClick = (record) => {
    navigate(`/strength-analysis/${selectedConcept}/${record.unit}`);
  };

  const columns = [
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (text, record) => (
        <Badge.Ribbon text="80%" color="green">
          <a onClick={() => onUnitClick(record)}>{text}</a>
        </Badge.Ribbon>
      ),
    },
  ];

  const data = selectedConcept
    ? Object.keys(props.conceptData[selectedConcept]).map((unit, index) => ({
      key: index,
      unit,
    }))
    : [];

  useEffect(() => {
    if (props.concepts && props.concepts.length > 0) {
      setSelectedConcept(props.concepts[0]);
    }
  }, [props.concepts]);

  const handleConceptChange = (value) => {
    setSelectedConcept(value);
  };

  return (
    <div className="strand-root">
      {props.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Row justify="space-between" align="middle" className="strand-header">
            <Col xs={24} sm={12}>
              <Title level={5} className="strand-title">
                Select Strand
              </Title>
            </Col>
            <Col xs={24} sm={12} className="strand-select">
              <Select
                value={selectedConcept}
                onChange={handleConceptChange}
                style={{ width: '100%' }}
                placeholder="Choose a concept"
              >
                {props.concepts.map((concept) => (
                  <Option key={concept} value={concept}>
                    {concept}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="unit"
            className="strand-table"
          />
        </>
      )}
    </div>
  );
}
