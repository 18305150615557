import React from 'react';
import { Menu ,Grid } from 'antd';
import {
  HomeOutlined,
  RadarChartOutlined,
  AuditOutlined
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import './TopNavStyles.css';

const { useBreakpoint } = Grid;

const menuItems = [
  {
    key: '/',
    icon: <HomeOutlined />,
    label: 'Overview',
  },
  {
    key: '/strength-analysis',
    icon: <RadarChartOutlined />,
    label: 'Strength Analysis',
  },
  {
    key: '/feedback',
    icon: <AuditOutlined />,
    label: 'Feedbacks',
  },
  // {
  //   key: '/mapt',
  //   icon: <LineChartOutlined />,
  //   label: 'MAPT',
  // },
  // {
  //   key: '/sapt',
  //   icon: <LineChartOutlined />,
  //   label: 'SAPT',
  // },
  // {
  //   key: '/eapt',
  //   icon: <LineChartOutlined />,
  //   label: 'EAPT',
  // }
];

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();

  // Determine if the device is mobile based on screen width
  const isMobile = !screens.md; // md breakpoint is 768px

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  const adjustedMenuItems = menuItems.map((item) => ({
    key: item.key,
    icon: (
      <div className="nav-icon">
        {item.icon}
        {isMobile && <div className="nav-label">{item.label}</div>}
      </div>
    ),
    label: !isMobile ? item.label : '',
  }));

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      onClick={handleMenuClick}
      items={adjustedMenuItems}
      className={isMobile ? 'bottom-nav centered-nav' : 'top-nav centered-nav'}
    />
  );
};

export default NavBar;
