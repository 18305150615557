import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Store/hooks';
import {
    Select,
    Skeleton,
    Row,
    Col,
    Typography,
    Card,
    Drawer,
    Button,
    Grid,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import ConceptRadarChartTemplateApex from '../ConceptRadarCharts/ConceptRadarChartTemplateApex';
import HowToGuideSA from './HowToGuideSA';
import { TypeRadarGraphResponseMapper } from '../../Services/DataMappers/TypeRadarGraphResponseMapper';
import { LevelRadarGraphResponseMapper } from '../../Services/DataMappers/LevelRadarGraphResponseMapper';
import { useParams } from 'react-router-dom';
import { GET_CONCEPT_SUMMARY_FOR_STRENGTH } from '../../Redux/Actions/StudentRadarChartActions';
import { filterAndMapByClassName } from '../../Services/DataMappers/FilterAndMapByClassName';
import { StudentListZCC } from '../../MockData/StudentLIstZCC';
import RenderOnRole from '../RouteHelpers/RenderOnRole';
import './styles.css';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const classRooms = [
    { value: '8EA', label: '8EA' },
    { value: '8EB', label: '8EB' },
    { value: '8EC', label: '8EC' },
    { value: '8ED', label: '8ED' },
];

export default function StrengthAnalysis(props) {
    const dispatch = useAppDispatch();
    const { pathStrand, pathUnit } = useParams();
    const isLoading = useAppSelector(
      (state) => state.studentRadarGraphReducer.isLoading
    );
    const currentStudentEmail = useAppSelector(
      (state) => state.studentProfileReducer.currentStudent.email
    );
    const levelWiseData = useAppSelector(
      (state) =>
        state.studentRadarGraphReducer.strengthAnalysis.strandWiseCountByLevel
    );
    const typeWiseData = useAppSelector(
      (state) =>
        state.studentRadarGraphReducer.strengthAnalysis.strandWiseCountByType
    );

    const [selectedClass, setSelectedClass] = useState();
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedStrand, setSelectedStrand] = useState();
    const [selectedUnit, setSelectedUnit] = useState();
    const [selectedConcept, setSelectedConcept] = useState();
    const [classes, setClasses] = useState([]);
    const [students, setStudents] = useState([]);
    const [stands, setStrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [concepts, setConcepts] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const screens = useBreakpoint();

    useEffect(() => {
        setClasses(classRooms);
        dispatch({
            type: GET_CONCEPT_SUMMARY_FOR_STRENGTH,
            payload: currentStudentEmail,
        });
    }, [dispatch, currentStudentEmail]);

    useEffect(() => {
        if (selectedStudent) {
            dispatch({
                type: GET_CONCEPT_SUMMARY_FOR_STRENGTH,
                payload: selectedStudent,
            });
        }
    }, [dispatch, selectedStudent]);

    useEffect(() => {
        if (levelWiseData) {
            if (pathStrand && pathUnit) {
                const strands = Object.keys(levelWiseData);
                const unitNames = Object.keys(levelWiseData[pathStrand]);
                const concepts = Object.keys(levelWiseData[pathStrand][pathUnit]);
                setStrands(strands);
                setSelectedStrand(pathStrand);
                setUnits(unitNames);
                setSelectedUnit(pathUnit);
                setConcepts(concepts);
                setSelectedConcept(concepts[0]);
            } else {
                const strands = Object.keys(levelWiseData);
                const unitNames = Object.keys(levelWiseData[strands[0]]);
                const concepts = Object.keys(
                  levelWiseData[strands[0]][unitNames[0]]
                );
                setStrands(strands);
                setSelectedStrand(strands[0]);
                setUnits(unitNames);
                setSelectedUnit(unitNames[0]);
                setConcepts(concepts);
                setSelectedConcept(concepts[0]);
            }
        }
    }, [levelWiseData, pathStrand, pathUnit]);

    const handleConceptsAndUnitsChange = (value, type) => {
        if (type === 'strand') {
            setSelectedStrand(value);
            const units = Object.keys(levelWiseData[value]);
            setUnits(units);
            setSelectedUnit(units[0]);
            const concepts = Object.keys(levelWiseData[value][units[0]]);
            setConcepts(concepts);
            setSelectedConcept(concepts[0]);
        }
        if (type === 'unit') {
            setSelectedUnit(value);
            const concepts = Object.keys(levelWiseData[selectedStrand][value]);
            setConcepts(concepts);
            setSelectedConcept(concepts[0]);
        }
        if (type === 'concept') {
            setSelectedConcept(value);
        }
    };

    const handleYearChange = (value) => {
        setStudents(filterAndMapByClassName(StudentListZCC.data, value));
        setSelectedClass(value);
    };

    const handleStudentChange = (value) => {
        setSelectedStudent(value);
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const onClose = () => {
        setDrawerVisible(false);
    };

    return (
      <div className="strength-analysis-container">
          {/* Filters Section */}
          {screens.xs ? (
            // On mobile screens, show the button to open drawer
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={showDrawer}
              className="filters-drawer-button"
            >
                Filters
            </Button>
          ) : (
            // On larger screens, show the filters as usual
            <Row gutter={[16, 16]} className="filters-row">
                <RenderOnRole roles={['admin', 'school']}>
                    <Col xs={24} sm={12} md={6}>
                        <Text type="secondary" className="filter-label-a">
                            Class
                        </Text>
                        <Select
                          size="middle"
                          value={selectedClass}
                          onChange={handleYearChange}
                          style={{ width: '100%' }}
                          placeholder="Class"
                          options={classes}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Text type="secondary" className="filter-label-a">
                            Student
                        </Text>
                        <Select
                          size="middle"
                          value={selectedStudent}
                          onChange={handleStudentChange}
                          style={{ width: '100%' }}
                          placeholder="Student"
                          options={students}
                        />
                    </Col>
                </RenderOnRole>
                <Col xs={24} sm={12} md={6}>
                    <Text type="secondary" className="filter-label-a">
                        Strand
                    </Text>
                    <Select
                      size="middle"
                      value={selectedStrand}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'strand')}
                      style={{ width: '100%' }}
                      placeholder="Strand"
                      options={stands.map((item) => ({ label: item, value: item }))}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Text type="secondary" className="filter-label-a">
                        Unit
                    </Text>
                    <Select
                      size="middle"
                      value={selectedUnit}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'unit')}
                      style={{ width: '100%' }}
                      placeholder="Unit"
                      options={units.map((item) => ({ label: item, value: item }))}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Text type="secondary" className="filter-label-a">
                        Concept
                    </Text>
                    <Select
                      size="middle"
                      value={selectedConcept}
                      onChange={(v) => handleConceptsAndUnitsChange(v, 'concept')}
                      style={{ width: '100%' }}
                      placeholder="Concept"
                      options={concepts.map((item) => ({ label: item, value: item }))}
                    />
                </Col>
            </Row>
          )}

          {/* Drawer for mobile filters */}
          <Drawer
            title="Filters"
            placement="top"
            closable={true}
            onClose={onClose}
            visible={drawerVisible}
            className="filters-drawer"
            height="auto"
          >
              <Row gutter={[16, 16]} className="filters-row">
                  <RenderOnRole roles={['admin', 'school']}>
                      <Col xs={24} sm={12} md={6}>
                          <Text type="secondary" className="filter-label-a">
                              Class
                          </Text>
                          <Select
                            size="middle"
                            value={selectedClass}
                            onChange={handleYearChange}
                            style={{ width: '100%' }}
                            placeholder="Class"
                            options={classes}
                          />
                      </Col>
                      <Col xs={24} sm={12} md={6}>
                          <Text type="secondary" className="filter-label-a">
                              Student
                          </Text>
                          <Select
                            size="middle"
                            value={selectedStudent}
                            onChange={handleStudentChange}
                            style={{ width: '100%' }}
                            placeholder="Student"
                            options={students}
                          />
                      </Col>
                  </RenderOnRole>
                  <Col xs={24} sm={12} md={6}>
                      <Text type="secondary" className="filter-label-a">
                          Strand
                      </Text>
                      <Select
                        size="middle"
                        value={selectedStrand}
                        onChange={(v) => handleConceptsAndUnitsChange(v, 'strand')}
                        style={{ width: '100%' }}
                        placeholder="Strand"
                        options={stands.map((item) => ({ label: item, value: item }))}
                      />
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                      <Text type="secondary" className="filter-label-a">
                          Unit
                      </Text>
                      <Select
                        size="middle"
                        value={selectedUnit}
                        onChange={(v) => handleConceptsAndUnitsChange(v, 'unit')}
                        style={{ width: '100%' }}
                        placeholder="Unit"
                        options={units.map((item) => ({ label: item, value: item }))}
                      />
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                      <Text type="secondary" className="filter-label-a">
                          Concept
                      </Text>
                      <Select
                        size="middle"
                        value={selectedConcept}
                        onChange={(v) => handleConceptsAndUnitsChange(v, 'concept')}
                        style={{ width: '100%' }}
                        placeholder="Concept"
                        options={concepts.map((item) => ({ label: item, value: item }))}
                      />
                  </Col>
              </Row>
          </Drawer>

          {/* Charts and Guide Section */}
          <Row gutter={[16, 16]} className="charts-guide-row">
              {/* Charts Section */}
              <Col xs={24} lg={16}>
                  {isLoading ? (
                    <Skeleton active />
                  ) : (
                    levelWiseData &&
                    selectedStrand &&
                    selectedUnit &&
                    selectedConcept && (
                      <div className="charts-section">
                          <Title level={4} className="selected-concept-title">
                              {selectedConcept}
                          </Title>
                          <Row gutter={[16, 16]}>
                              <Col xs={24} md={12}>
                                  <Card hoverable className="radarchartCard">
                                      <Title level={5} className="radarChartTitle">
                                          Question Type
                                      </Title>
                                      <ConceptRadarChartTemplateApex
                                        labels={['Theory', 'Composite', 'Worded']}
                                        data={TypeRadarGraphResponseMapper(
                                          typeWiseData[selectedStrand][selectedUnit][
                                            selectedConcept
                                            ]
                                        )}
                                      />
                                  </Card>
                              </Col>
                              <Col xs={24} md={12}>
                                  <Card hoverable className="radarchartCard">
                                      <Title level={5} className="radarChartTitle">
                                          Question Level
                                      </Title>
                                      <ConceptRadarChartTemplateApex
                                        labels={['Easy', 'Medium', 'Hard']}
                                        data={LevelRadarGraphResponseMapper(
                                          levelWiseData[selectedStrand][selectedUnit][
                                            selectedConcept
                                            ]
                                        )}
                                      />
                                  </Card>
                              </Col>
                          </Row>
                      </div>
                    )
                  )}
              </Col>
              {/* How To Guide Section */}
              <Col xs={24} lg={8}>
                  <Card className="howToGuideCard" bordered={false}>
                      <HowToGuideSA />
                  </Card>
              </Col>
          </Row>
      </div>
    );
}
